<template lang="pug">
v-card.invite-users
  v-card-head.invite-users--header
    h2.title {{ 'pages.skill_gap.invite_users' | translate }}
    v-btn.invite-users--close(icon, success, @click="onClose")
      icon-close(color="#A0AAC3")
  v-card-content.invite-users--content
    .invite-users--heade
      .invite-users--search
        v-dropdown(@toggle="toggleInput = $event")
          v-input.input-search(
            @focus="focusInput = $event"
            :placeholder="placeholderInput",
            v-model="search",
            @input="isSearch"
          )
            template(slot="append")
              v-chevron
          template(slot="content")
            v-card.invite-users--droplist(elevation)
              v-scrollable(:min-height="20" :bottom-offset="15", @scroll-complete="onNextPage")
                v-list
                  v-list-item.employee-item(
                    v-for="item in employeesList",
                    :key="`search_${item.id}`",
                    @click=""
                  )
                    slot(name="icon")
                      v-avatar(:size="48", :src="item.profilePictureUrl")
                    v-list-item-content
                      v-list-item-title
                        span {{ item.name }}
                      v-list-item-subtitle(v-if="item.position && item.position.name")
                        span {{  item.position.name }}
                    v-list-item-actions
                      v-btn(
                        v-if="!selectedEmployees.find((el) => el.id === item.id)",
                        icon,
                        success,
                        @click="addEmployees(item)"
                      )
                        icon-plus
                      v-btn(v-else, icon, danger, @click="removeEmployees(item)")
                        icon-minus
                template(v-if="searchLoading")
                  v-list-item.employee-item
                    v-skeleton(width="100%")
                  v-list-item.employee-item
                    v-skeleton(width="100%")
        .invite-users--acess
          v-dropdown(width="145", right, auto-close)
            span {{ (types.find((el) => el.key === type) || {}).name | translate }}
            v-chevron
            template(slot="content")
              v-card(elevation)
                v-list(flat)
                  v-list-item(
                    v-for="item in typesNotOwner",
                    :key="`acess_${item.key}`",
                    @click="type = item.key"
                  )
                    span {{ item.name | translate }}
      v-btn(@click="addCollaborators") {{ 'ui.buttons.invite' | translate }}
    v-list.invite-users--result(v-if="loaded")
      v-list-item
        v-skeleton(width="100%")
      v-list-item
        v-skeleton(width="100%")
      v-list-item
        v-skeleton(width="100%")
    v-scrollable.invite-users--scroll(v-else-if="!loaded && items.length")
      v-list.invite-users--result
        v-list-item( v-for="item in items" :key="`result_${item.employeeId || item.id}`")
            slot(name="icon")
              v-avatar(
                :size="48",
                :src="item.profilePictureUrl || item.profilePicture"
              )
            v-list-item-content
              v-list-item-title
                span {{ item.firstName + ' ' + item.lastName }}
              v-list-item-subtitle
                span {{ item.positionName }}
            v-list-item-actions
              template(v-if="item.typeId === 'OWNER'")
                span.text-type {{ (types.find((el) => el.key === item.typeId) || {}).name | translate }}
              v-dropdown(v-else, flat, width="160", right, width-auto, auto-close)
                span {{ (types.find((el) => el.key === item.typeId) || {}).name | translate }}
                v-chevron
                template(slot="content")
                  v-card(elevation)
                    v-list(flat)
                      v-list-item(
                        v-for="el in typesNotOwner",
                        :key="`invited_${el.key}`",
                        @click="setCollaboratorType(item, el.key)"
                      )
                        v-radio(
                          :id="el.key",
                          :label="el.name | translate",
                          :active="item.typeId === el.key"
                        )
                      v-list-item.danger(@click="onRemove(item)")
                        .item-delete
                          icon-delete
                          span {{ 'ui.buttons.remove' | translate }}
    picture.invite-users--picture(v-else)
      img(:src="inviteUsers", alt="image Select Employees")
</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'
import IconClose from '@/components/svg/IconClose.vue'
import VChevron from '@/components/svg/icons/IconChevron.vue'
import IconPlus from '@/components/svg/IconPlusAddToList.vue'
import IconMinus from '@/components/svg/icons/IconMinus.vue'
import IconDelete from '@/components/svg/icons/IconDelete.vue'
import inviteUsers from '@/assets/img/skill-matrix/invite-users.svg'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  name: 'InviteUsersModal',
  components: { IconClose, VChevron, IconPlus, IconMinus, IconDelete },
  mixins: [scrollPaginationSkeletonsMixin],

  props: {
    matrix: Object
  },

  async mounted () {
    this.getlistCollaborators()
    await this.isSearch({ page: 1 })
    this.initScrollPagination(
      { pagination: this.paginationParams },
      this.loadNextPage
    )
    this.loaded = false
  },

  data: () => ({
    type: 'VIEW_ONLY',
    types: [
      { name: 'pages.skill_gap.owner', key: 'OWNER' },
      { name: 'pages.skill_gap.view_acess', key: 'VIEW_ONLY' },
      { name: 'pages.skill_gap.full_acess', key: 'FULL_ACCESS' }
    ],
    items: [],
    selectedEmployees: [],
    loaded: true,
    search: '',
    inviteUsers,
    searchLoading: false,
    focusInput: false,
    toggleInput: false
  }),
  computed: {
    ...mapGetters('skillMatrix', ['searchListCollaborators', 'paginationParams']),

    typesNotOwner () {
      return this.types.filter((el) => el.key !== 'OWNER')
    },

    employeesList () {
      return this.searchListCollaborators.map((item) => ({ ...item, name: `${item.firstName} ${item.lastName}` }))
    },
    placeholderInput () {
      return this.selectedEmployees.length && !this.focusInput && !this.toggleInput
      ? this.$t('pages.skill_gap.employees_selected') + `: ` + this.selectedEmployees.length
      : this.$t('pages.skill_gap.select_employees_or_groups')
    }
  },

  methods: {
    ...mapActions('skillMatrix', ['searchCollaborators']),

    async isSearch ({ page = 1 }) {
      this.searchLoading = true
      const params = { params: { q: this.search, page } }
      await this.searchCollaborators({ matrixID: this.matrix.id, params })
      this.searchLoading = false
    },

    addEmployees (item) {
      this.selectedEmployees.push(item)
    },
    removeEmployees (item) {
      this.selectedEmployees = this.selectedEmployees.filter(
        (el) => el.id !== item.id
      )
    },

    async addCollaborators () {
      const items = this.selectedEmployees.map((item) => ({
        ...item,
        positionName: item?.position?.name || 'none',
        typeId: this.type
      }))
      this.matrix.collaboratorEmployeeProfilePictures.push(
        ...this.selectedEmployees.map((item) => item.profilePictureUrl)
      )
      this.selectedEmployees = []
      const payload = {
        employeeIds: items.map((item) => item.id),
        typeId: this.type
      }
      await api.matrix.addCollaborators(this.matrix.id, payload)
      this.getlistCollaborators()
      this.isSearch({ page: 1 })
    },

    setCollaboratorType (item, type) {
      item.typeId = type
      const payload = { employeeIds: [item.employeeId], typeId: type }
      api.matrix.setCollaboratorsType(this.matrix.id, payload)
    },

    onRemove (item) {
      const index = this.items
        .map((item) => item.employeeId)
        .indexOf(item.employeeId)
      if (index !== -1) {
        this.items.splice(index, 1)
        const payload = { employeeIds: [item.employeeId] }
        api.matrix.removeCollaborators(this.matrix.id, payload)
        const index2 = this.matrix.collaboratorEmployeeProfilePictures.indexOf(
          item.profilePicture
        )
        if (index2 !== -1) {
          this.matrix.collaboratorEmployeeProfilePictures.splice(index, 1)
        }
      }
    },

    async loadNextPage () {
      const page = this.paginationParams.page
        ? this.paginationParams.page + 1
        : 1
      this.searchLoading = true
      await this.isSearch({ page, isPushed: true })
      this.searchLoading = false
    },

    onClose () {
      this.$emit('close')
    },
    async getlistCollaborators () {
      const { data } = await api.matrix.listCollaborators(this.matrix.id)
      this.items = data
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-users {
  height: inherit;
  display: flex;
  flex-direction: column;
  &--scroll {
    height: 432px!important;
  }
  &--header {
    position: relative;
    padding: 24px 24px 0;
  }
  &--close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    color: $body-text-2;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &--content {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
  &--droplist{
    padding: 8px 4px 24px 16px;
    .v-list{
      padding-right: 20px;
    }
    .employee-item{
      padding: 12px 8px 11px;
      box-sizing: border-box;
    }
  }
  &--heade {
    display: flex;
    gap: 16px;
    margin-bottom: 13px;
  }
  &--search {
    display: flex;
    gap: 12px;
    width: 528px;
    background-color: $light-blue;
    border-radius: $border-radius;
    .v-dropdown {
      width: 100%;
    }
    .input-search {
      width: 100%;
      padding: 12px 16px;
      box-sizing: border-box;
      background-color: transparent;
      ::v-deep .v-input__field {
        padding: 0;
      }
      ::v-deep .v-input__append {
        color: $body-text-2;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  &--acess {
    ::v-deep .v-dropdown__toggler {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 16px;
      color: $body-text-2;
      white-space: nowrap;
      span {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .v-list__item {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: $body-text;
      padding: 8px 16px !important;
      &:hover {
        background-color: $website-color;
      }
    }
  }
  &--picture {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--result {
    padding-right: 20px;

    .v-list__item{
      padding: 12px 12px 12px 8px;
      height: 72px;
    }
    ::v-deep .v-dropdown {
      &.open {
        .v-dropdown__toggler {
          color: $color-dark-green;
          svg {
            transform: rotate(180deg);
          }
        }
      }
      &__toggler {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 2px 0;
        color: $body-text;
        white-space: nowrap;
        span {
          font-size: 16px;
          line-height: 16px;
          font-weight: 400;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      &__content {
        .v-list__item {
          height: auto;
          padding: 8px 16px !important;
        }
        .v-radio {
          &__state-icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            &--active {
              &::after {
                width: 16px;
                height: 16px;
              }
            }
          }
          &__label {
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            color: $body-text;
          }
        }
        .item-delete {
          display: flex;
          align-items: center;
          gap: 8px;
          svg {
            width: 24px;
            height: 24px;
          }
          span {
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.text-type {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: $body-text;
}
.v-list__item {
  padding: 12px;
  box-sizing: border-box;
  .v-avatar {
    margin-right: 16px;
  }
  .avatar {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 16px;
    background-color: $color-light-green;
    ::v-deep svg {
      color: $color-white;
      width: 24px;
      height: 24px;
    }
  }
  &-title,
  &-subtitle {
    font-size: 14px;
    line-height: 16px;
    color: $body-text;
  }
  &-title {
    font-weight: 700;
  }
  &-subtitle {
    font-weight: 400;
    margin-top: 4px;
  }
  ::v-deep .v-btn {
    width: 24px;
    height: 24px;
  }
}
</style>
